import { useTranslation } from 'react-i18next';
import { ButtonSelector } from "../";

interface IAnimalGenderSelectionProps {
  gender: string;
  onGenderSelected: (gender: string) => void;
}

export const AnimalGenderSelection = (props: IAnimalGenderSelectionProps) => {
  const { t } = useTranslation();
  const maleKey = "male";
  const femaleKey = "female";

  return (
    <>
      <h2>{t("genderSelection")}</h2>
      <ButtonSelector
        key={`genderSelectionButton-${maleKey}`}
        onClick={() => props.onGenderSelected(maleKey)}
        selected={maleKey === props.gender}>
          {t("gender.male")}
      </ButtonSelector>
      <ButtonSelector
        key={`genderSelectionButton-${femaleKey}`}
        onClick={() => props.onGenderSelected(femaleKey)}
        selected={femaleKey === props.gender}>
          {t("gender.female")}
      </ButtonSelector>
    </>
  );
}