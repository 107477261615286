import { useTranslation } from 'react-i18next';
import { ButtonSelector } from "../";

interface IAnimalSterilizedSelectionProps {
  gender: string;
  sterilized: boolean | null;
  onSterilizedSelected: (sterilized: boolean) => void;
}

export const AnimalSterilizedSelection = (props: IAnimalSterilizedSelectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      {props.gender === "male" && <h2>{t("sterilizedSelectionTitle.male")}</h2>}
      {props.gender === "female" && <h2>{t("sterilizedSelectionTitle.female")}</h2>}
      <ButtonSelector
        key={"sterilizedSelectionButton-yes"}
        onClick={() => props.onSterilizedSelected(true)}
        selected={props.sterilized === true}>
          {t("buttons.yes")}
      </ButtonSelector>
      <ButtonSelector
        key={"genderSelectionButton-no"}
        onClick={() => props.onSterilizedSelected(false)}
        selected={props.sterilized === false}>
          {t("buttons.no")}
      </ButtonSelector>
    </>
  );
}