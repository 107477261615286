import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Navigate, Routes, Outlet } from "react-router-dom";
import { Layout, Login, Register, PageNotFound, Diagnosis } from "../";
import { AuthService } from '../../services';

export const AppRouter = () => {
  const authService = new AuthService();
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const handleLogin = (isLogged: boolean) => {
    setIsAuth(isLogged);
    if (isLogged) {
      window.location.href = "/";
    }
  };

  const handleLogout = async() => {
    await authService.logout();
    setIsAuth(false);
    window.location.href = "https://vetsmate.app";
  };

  const handleRegister = () => {
    setIsAuth(false);
    window.location.href = "/login";
  }

  const isAuthenticated = async () : Promise<boolean> => {
    const response = await authService.checkAuthentication();
    return response.success && response.data as boolean;
  }

  const AuthRoute = ({element}: { element: JSX.Element }) => {
    const [auth, setAuth] = useState<boolean | null>(null);
    
    useEffect(() => {
      // Check authentication status when the component mounts
      isAuthenticated()
        .then((isAuthenticated) => {
          setAuth(isAuthenticated);
          setIsAuth(isAuthenticated);
        })
        .catch(() => setAuth(false)); // Set to false by default in case of errors
    }, []);
  
    if (auth === null) {
      return <div>Loading...</div>;
    }
  
    return !auth ? element : <Navigate to="/" />;
  }

  const PrivateRoute = ({element}: { element: JSX.Element }) => {
    const [auth, setAuth] = useState<boolean | null>(null);
    
    useEffect(() => {
      // Check authentication status when the component mounts
      isAuthenticated()
        .then((isAuthenticated) => {
          setAuth(isAuthenticated);
          setIsAuth(isAuthenticated);
        })
        .catch(() => setAuth(false)); // Set to false by default in case of errors
    }, []);
  
    if (auth === null) {
      return <div>Loading...</div>;
    }
  
    return auth ? element : <Navigate to="/login" />;
  }

  return (
    <Router>
      <Routes>
        <Route element={<Layout onLogout={handleLogout} isAuthenticated={isAuth}><Outlet /></Layout>}>
          
          {/* Public routes */}
          <Route path="/login"    element={<AuthRoute element={<Login onLogin={handleLogin} />}/>}/>
          <Route path="/register" element={<AuthRoute element={<Register onRegister={handleRegister}/>}/>}/>

          {/* Private routes */}
          <Route path={"/"}          element={<PrivateRoute element={<Diagnosis />} />}/>
          <Route path={"/diagnosis"} element={<PrivateRoute element={<Diagnosis />} />}/>

          {/* Page not found */}
          <Route path="*" element={<PageNotFound />}/>
        </Route>
      </Routes>
    </Router>
  );
};
