
import { useTranslation } from 'react-i18next';
import { Textarea } from '..';

interface IAnimalSymptomsSelectionProps {
  selectedSymptoms?: string;
  onSymptomSelected: (symptoms: string) => void;
}

export const AnimalSymptomsSelection = (props: IAnimalSymptomsSelectionProps) => {
  const { t } = useTranslation();

  const onSymptomChangeHandler = (text: string, isTextOutOfLimit?: boolean) => {
    if (isTextOutOfLimit) {
      props.onSymptomSelected("");
    } else {
      props.onSymptomSelected(text);
    }
  }

  const placeholder = t("describeSymptomsPlaceholder");
  return (
    <>
      <h2>{t("describeSymptoms")}</h2>
      <p>{t("describeSymptomsSubtitle")}</p>
      <Textarea placeholder={placeholder} maxLength={1000} onTextChange={onSymptomChangeHandler} defaultValue={props.selectedSymptoms}/>
    </>
  );
}