import { useTranslation } from 'react-i18next';
import { ButtonSelector } from "../";
import { AnimalsService } from "../../services";
import { useEffect, useState } from 'react';

interface IAnimalSpecieSelectionProps {
  specieGuid: string;
  onSpecieSelected: (specieGuid: string, specie: string) => void;
}

interface ISpecie {
  guid: string;
  name: string;
}

export const AnimalSpecieSelection = (props: IAnimalSpecieSelectionProps, {animalsService = new AnimalsService()}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [species, setSpecies] = useState<Array<ISpecie>>([]);

  const loadSpecies = async () => {
    setLoading(true);
    const response = await animalsService.getSpecies();
    const apiSpecies = response.data.map((x: ISpecie) => x);
    setSpecies(apiSpecies);
    setLoading(false);
  }

  useEffect(() => {
    loadSpecies();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const specieItems = species.map(specie =>
    <ButtonSelector
      key={`specieSelectionButton-${specie.guid}`}
      onClick={() => props.onSpecieSelected(specie.guid, specie.name)}
      selected={specie.guid === props.specieGuid}>
        {specie.name}
    </ButtonSelector>)

  return (
    <>
      <h2>{t("specieSelection")}</h2>
      {loading && t("loading")}
      {!loading && <>{specieItems}</>}
    </>
  );
}