
export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const isValidGuid = (guid: string | undefined | null) : boolean => {
  return guid !== null && guid !== undefined && (typeof guid === 'string') && guidRegex.test(guid);
}

export const isEmptyGuid = (guid: string | undefined | null) : boolean => {
  return guid === null || guid === undefined || guid === EmptyGuid;
}
