import { useTranslation } from 'react-i18next';
import { Input } from "..";
import { ChangeEvent } from 'react';

interface IAnimalWeightSelectionProps {
  selectedWeight?: number;
  onWeightSelected: (weight: number) => void;
  onWeightSubmitted?: () => void;
}

export const AnimalWeightSelection = (props: IAnimalWeightSelectionProps) => {
  const { t } = useTranslation();

  const onWeightChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    props.onWeightSelected(Number.parseFloat(value));
  }

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.onWeightSubmitted?.();
    }
  }

  return (
    <>
      <h2>{t("weightSelection")}</h2>
      <Input
        type="number"
        id="weight"
        name="weight"
        min={0} max={99}
        step={1}
        onChange={onWeightChangeHandler}
        onKeyUp={onKeyUpHandler}
        defaultValue={props.selectedWeight === 0 ? undefined : props.selectedWeight ?? undefined}
      /> Kg
    </>
  );
}