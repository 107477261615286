
export const getDeviceFingerprintHash = async () => {
  const fingerprint = getDeviceFingerprint();

  const encoder = new TextEncoder();
  const data = encoder.encode(fingerprint);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export const getDeviceFingerprint = () => {
  const fingerprintArray = [];

  // User Agent
  fingerprintArray.push(navigator.userAgent);

  // Language
  fingerprintArray.push(navigator.language);

  // Screen resolution
  fingerprintArray.push(`${window.screen.width}x${window.screen.height}`);

  // Device pixel ratio
  fingerprintArray.push(`${window.devicePixelRatio}`);

  // Timezone offset
  fingerprintArray.push(new Date().getTimezoneOffset());

  // Combine all collected information into a single string
  const fingerprint = fingerprintArray.join('|');

  return fingerprint;
}
