import { DefaultTFuncReturn } from "i18next";
import styles from "./Tooltip.module.css";

export interface ITooltipProps {
  text: string | DefaultTFuncReturn;
  children: string | DefaultTFuncReturn | JSX.Element;
  position?: "top" | "bottom" | "right" | "left"
}

export const Tooltip = (props : ITooltipProps) => {

  const positionClass = props.position ?? "bottom";

  return (
    <div className={styles["tooltip"] + " " + styles[positionClass]}>
      {props.children}
      <span className={styles["tooltiptext"]}>{props.text}</span>
    </div>
  );
}