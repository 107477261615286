import { ChangeEvent, ClassAttributes, TextareaHTMLAttributes, useState } from "react"
import styles from "./Textarea.module.css";

export interface ITextareaProps {
  onTextChange?: (text: string, isTextOutOfLimit?: boolean) => void;
}

export const Textarea = (props: ITextareaProps
  & JSX.IntrinsicAttributes
  & ClassAttributes<HTMLTextAreaElement>
  & TextareaHTMLAttributes<HTMLTextAreaElement>) => {

    const [length, setLength] = useState<number>(props.defaultValue?.toString().length ?? 0);

    const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const text = event.target.value;
      setLength(text.length);
      props.onTextChange?.(text, isOutOfLimit(text));
    }

    const minLength = props.minLength ?? 0;
    const maxLength = props.maxLength ?? 0;
    const isLengthLimited = (minLength > 0 || maxLength > 0) && (minLength < maxLength || maxLength === 0);

    const isOutOfLimit = (text? : string) => {
      const textLength = (text?.length ?? length);

      return isLengthLimited && (minLength > textLength || (maxLength > 0 && textLength > maxLength));
    }

    const getTextLengthLimits = () => {
      let limit = "";
      if (minLength > 0) {
        limit += minLength + " / ";
      }
      limit += length;
      if (maxLength > 0) {
        limit += " / " + maxLength
      }
      return limit;
    }

    return (
      <>
        <textarea className={styles.textarea} {...props} onChange={onChangeHandler}/>
        {isLengthLimited && <p className={isOutOfLimit() ? styles.invalid : ""}>{getTextLengthLimits()}</p>}
      </>
    );
}