
export const getCookie = (name: string) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=');
    const cookieName = cookie[0];
    const cookieValue = cookie[1];

    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }

  return "";
}
