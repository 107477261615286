import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimalsService } from "../../services";
import { ButtonSelector, Select } from "..";

interface IAnimalBreedSelectionProps {
  specieGuid: string;
  breed?: string;
  mixedBreed?: boolean;
  onBreedSelected: (breed: string) => void;
  onMixedBreedSelected: (mixedBreed: boolean) => void;
}

interface IBreed {
  guid: string;
  name: string;
}

export const AnimalBreedSelection = (props: IAnimalBreedSelectionProps, {animalsService = new AnimalsService()}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [breeds, setBreeds] = useState<Array<IBreed>>([]);
  const [mixedBreed, setMixedBreed] = useState<boolean>(props.mixedBreed ?? false);

  const onBreedChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    props.onBreedSelected(event.target.value);
  }

  const onMixedBreedChangeHandler = () => {
    setMixedBreed(!mixedBreed);
    props.onMixedBreedSelected(!mixedBreed);
  }

  const loadBreeds = async () => {
    setLoading(true);
    const response = await animalsService.getBreeds(props.specieGuid);
    const apiBreeds = response.data.map((x: IBreed) => x).sort((a: IBreed, b: IBreed) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      return 0;
    });
    setBreeds(apiBreeds);
    setLoading(false);
  }

  useEffect(() => {
    loadBreeds();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  return (
    <>
      <h2><label htmlFor="breed">{t("breedSelection")}</label></h2>
      <br />
      {loading && t("loading")}
      {!loading &&
        <>
        <Select name="breed" id="breed" onChange={onBreedChangeHandler} defaultValue={props.breed ?? ""}>
        <option key={"breed_option_empty"} value={""}></option>
          {breeds.map((breed) => 
            <option key={"breed_option_" + breed.guid} value={breed.name}>{breed.name}</option>
          )}
        </Select>
        <span style={{padding: "20px"}} />
        <ButtonSelector onClick={onMixedBreedChangeHandler} selected={props.mixedBreed}>{t("mixedBreed")}</ButtonSelector>
        </>
      }
    </>
  );
}