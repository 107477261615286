import { useState } from "react";
import styles from "./ButtonSelector.module.css";

export interface IButtonSelectorProps {
  type?: "button" | "submit" | "reset" | undefined;
  selected?: boolean;
  disabled?: boolean;
  children: string;
  onClick? : () => void;
}

export const ButtonSelector = (props : IButtonSelectorProps) => {
  const [selected, setSelected] = useState<boolean>(props.selected ?? false);

  const onClickHandler = () => {
    setSelected(!selected);
    props.onClick?.();
  }

  const selectedClass = selected ? styles["selected"] : "";

  return <button
    type={props.type}
    className={styles["button-selector"] + " " + selectedClass}
    disabled={props.disabled ?? false}
    onClick={onClickHandler}>
      {props.children}
    </button>
}