
import { useTranslation } from 'react-i18next';
import { Textarea } from '..';

interface IAnimalMedicalHistorySelectionProps {
  selectedMedicalHistory?: string;
  onMedicalHistorySelected: (symptoms: string) => void;
}

export const AnimalMedicalHistorySelection = (props: IAnimalMedicalHistorySelectionProps) => {
  const { t } = useTranslation();

  const onMedicalHistoryChangeHandler = (text: string) => {
    props.onMedicalHistorySelected(text);
  }

  const placeholder = t("medicalHistoryPlaceholder");
  return (
    <>
      <h2>{t("medicalHistoryTitle")}</h2>
      <Textarea
        placeholder={placeholder}
        maxLength={1000}
        onTextChange={onMedicalHistoryChangeHandler}
        defaultValue={props.selectedMedicalHistory} />
    </>
  );
}