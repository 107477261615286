/*import { decode, getCookie } from "../../utils";*/
import logoutIcon from "../../assets/LogOut.svg";
import vetsmateLogo from "../../assets/VetsmateLogo.png";
import { Tooltip } from "../../components";
import styles from "./Layout.module.css";
import { useTranslation } from "react-i18next";

export interface ILayoutProps {
  isAuthenticated?: boolean;
  children: JSX.Element;
  onLogout?: () => void;
}

export const Layout = (props : ILayoutProps) => {
  const { t } = useTranslation();
  // const jwtPayload = decode(getCookie("token"));
  // const userName = jwtPayload["name"];

  const logoutHandler = () => {
    props.onLogout?.();
  };

  return (
    <>
      {/* Header */}
      <div className={styles["brand"]}>
        <img alt="Vetsmate" src={vetsmateLogo} onClick={() => window.location.href = "/"} />
      </div>

      {/* Profile */}
      {props.isAuthenticated &&
        <div className={styles["profile"]}>
          {/* {userName && "Hi " + userName + " "} */}
          <a href="/diagnosis">{t("startAnotherDiagnosis")}</a>
          
          <span>
            <Tooltip text={t("logout")} position="left">
              <img alt={t("logout").toString()} src={logoutIcon} onClick={logoutHandler} />
            </Tooltip>
          </span>
        </div>
      }

      {/* Content */}
      <div className={styles["content"]}>
        {props.children}
      </div>
      
      {/* Footer */}
      {/* <div style={{backgroundColor: "#498a5b", color: "#ffffff", width: "100%", position: "absolute", bottom: "0px", overflow: "hidden"}}>
        <p style={{padding: "5px 0px 5px 10px"}}>©{(new Date()).getFullYear()} Vetsmate - All rights reserved.</p>
      </div> */}
    </>
  );
}