import { useTranslation } from "react-i18next";
import { ButtonSelector } from "../";
import styles from "./Diagnosis.module.css";
import { useState } from "react";

interface IAnimalAgeSelectionProps {
  ageRange?: string;
  onAgeSelected: (ageRange: string) => void;
}

export const AnimalAgeSelection = (props: IAnimalAgeSelectionProps) => {
  const { t } = useTranslation();
  const [ageRange, setAgeRange] = useState<string>(props.ageRange ?? "");

  const onAgeRangeSelectedHandler = (newAgeRange: string) => {
    props.onAgeSelected(newAgeRange);
    setAgeRange(newAgeRange);
  }

  return (
    <>
      <h2>{t("ageSelection")}</h2>
      <div className={styles.animalAgeSelection}>
        <div>
          <ButtonSelector
            onClick={() => onAgeRangeSelectedHandler("offspring")}
            selected={ageRange === "offspring"}>
              {t("ageRange.offspring")}
          </ButtonSelector>
        </div>
        <div>
          <ButtonSelector
            onClick={() => onAgeRangeSelectedHandler("adult")}
            selected={ageRange === "adult"}>
              {t("ageRange.adult")}
          </ButtonSelector>
        </div>
        <div>
          <ButtonSelector
            onClick={() => onAgeRangeSelectedHandler("geriatric")}
            selected={ageRange === "geriatric"}>
              {t("ageRange.geriatric")}
          </ButtonSelector>
        </div>
      </div>
    </>
  );
}