import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import styles from "./FeedbackDialog.module.css";

import feedbackDialogIcon from "../../assets/FeedbackDialog.svg"
import thumbsUpIcon from "../../assets/ThumbsUp.svg"
import thumbsDownIcon from "../../assets/ThumbsDown.svg"

export interface IFeedbackDialog {
  title : string;
  onThumbsUp : () => void;
  onThumbsDown : () => void;
}

export const FeedbackDialog = (props: IFeedbackDialog) => {
  const { t } = useTranslation();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);

  const onThumbsUpHandler = () => {
    setFeedbackSubmitted(true);
    props.onThumbsUp();
  }

  const onThumbsDownHandler = () => {
    setFeedbackSubmitted(true);
    props.onThumbsDown();
  }

  return (
    <div className={styles["feedback-dialog"]}>
      <img alt="feedback dialog" src={feedbackDialogIcon} />
      <div className={styles["feedback-content"]}>
        <p>{props.title}</p>

        {feedbackSubmitted && <p>{t("thankYou")}</p>}

        {!feedbackSubmitted &&
          <div className={styles["feedback-buttons"]}>
              <div className={styles["thumbsdown-button"]} onClick={onThumbsDownHandler}>
                <img alt="thumbs down" src={thumbsDownIcon} />
              </div>
              <div className={styles["thumbsup-button"]} onClick={onThumbsUpHandler}>
                <img alt="thumbs up" src={thumbsUpIcon} />
              </div>
          </div>
        }
      </div>
    </div>
  );
}