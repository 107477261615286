
import { useTranslation } from 'react-i18next';
import copyIcon from "../../assets/Copy.svg"
import styles from "./Diagnosis.module.css";
import { FeedbackDialog, Tooltip } from '../../components';
import { useEffect, useState } from 'react';
import { getTextBetweenTags } from '../../utils';

interface IDiagnosisResultProps {
  diagnosis: string;
  onFeedback?: (score: boolean, message: string) => void;
}

interface IResult {
  title: string;
  content: string;
}

export const DiagnosisResult = (props: IDiagnosisResultProps) => {
  const { t } = useTranslation();
  const [showCopiedText, setShowCopiedText] = useState(false);
  const [diagnoses, setDiagnoses] = useState<IResult[]>([]);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  const parseDiagnosis = () => {
    const diagnosesParsed : IResult[] = [];

    const errors = getTextBetweenTags("error", props.diagnosis);
    const diagnosesTitles = getTextBetweenTags("title", props.diagnosis);
    const diagnosesContents = getTextBetweenTags("description", props.diagnosis);

    if (errors.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        const error : IResult = { title: t("errors.error"), content: errors[0].trim() }
        diagnosesParsed.push(error);
      }
    }
    else if (diagnosesTitles.length === diagnosesContents.length) {
      for (let i = 0; i < diagnosesTitles.length; i++) {
        const diagnosisElement : IResult = { title: diagnosesTitles[i].trim(), content: diagnosesContents[i].trim() }
        diagnosesParsed.push(diagnosisElement);
      }
    }

    if (diagnosesParsed.length === 0) {
      const error : IResult = { title: t("errors.error"), content: t("errors.unknown") }
      diagnosesParsed.push(error);
    }

    setDiagnoses(diagnosesParsed);
  }

  const onCopyHandler = () => {
    let toCopy = "";
    diagnoses.forEach(d => {
      toCopy += d.title + "\r\n" + d.content + "\r\n\r\n";
    });
    toCopy += "Powered by Vetsmate";

    navigator.clipboard.writeText(toCopy);

    setShowCopiedText(true);

    setTimeout(() => {
      setShowCopiedText(false);
    }, 2000);
  }

  const onThumbsUpHandler = () => {
    props.onFeedback?.(true, "");
    setTimeout(() => {
      setShowFeedback(false);
    }, 3000);
  }

  const onThumbsDownHandler = () => {
    // TODO: show some kind of textarea
    props.onFeedback?.(false, "");
    setTimeout(() => {
      setShowFeedback(false);
    }, 3000);
  }

  useEffect(() => {
    parseDiagnosis();
    if (getTextBetweenTags("error", props.diagnosis).length === 0) {
      setTimeout(() => {
        setShowFeedback(true);
      }, 3000);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [props.diagnosis]);

  const copyLabel = t("buttons.copy");

  return (
    <>
      <h1>{t("diagnosisResultTitle")}</h1>
      <div className={styles["diagnosisResult"]}>
        {diagnoses.map((d, i) => (
          <p key={i}>
            <b>{d.title}</b><br/>
            {d.content}
          </p>
        ))}
      </div>
      <br/>
      <div className={styles["diagnosisResultCopyContainer"]}>
        <Tooltip text={copyLabel} position="bottom">
          <img className={styles["diagnosisResultCopy"]} alt={copyLabel} src={copyIcon} onClick={onCopyHandler} />
        </Tooltip>
        {showCopiedText && <div className={styles["copiedText"]}>{t("copied")}</div>}
      </div>
      {showFeedback && <FeedbackDialog title={t("feedbackQuestion")} onThumbsDown={onThumbsDownHandler} onThumbsUp={onThumbsUpHandler}/>}
    </>
  );
}