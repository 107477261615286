import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ca, en, es } from "./locale/locales";

import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      "en": {translation: en},
      "es": {translation: es},
      "ca": {translation: ca}
    },
    fallbackLng: "es",
  });

export default i18n;