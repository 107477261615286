import { getDeviceFingerprintHash } from "../utils";
import { APIResponse, BaseAPI } from "./api";

export class AuthService {
  private api: BaseAPI;

  private usersUrl = "api/authentication";
  
  constructor() {
    this.api = new BaseAPI();
  }

  async login(username: string, password: string): Promise<APIResponse<boolean>> {
    const url = this.usersUrl + "/login";
    const deviceFingerprint = await getDeviceFingerprintHash();
    const data = {
      username: username,
      password: password,
      deviceFingerprint: deviceFingerprint
    };
    const response = await this.api.post(url, data);
    return response;
  }

  async register(
    username: string,
    password: string,
    name: string,
    surname: string,
    professionalProfile: string,
    language: string
  ): Promise<APIResponse<boolean>> {
    const url = this.usersUrl + "/register";
    const deviceFingerprint = await getDeviceFingerprintHash();
    const data = {
      username: username,
      password: password,
      name: name,
      surname: surname,
      professionalProfile: professionalProfile,
      language: language,
      deviceFingerprint: deviceFingerprint
    };
    const response = await this.api.post(url, data);
    return response;
  }

  async checkAuthentication(): Promise<APIResponse<boolean>> {
    const url = this.usersUrl + "/checkAuthentication";
    const response = await this.api.get(url);
    return response;
  }

  async logout(): Promise<void> {
    const url = this.usersUrl + "/logout";
    await this.api.get(url);
    return;
  }

  async professionalProfiles(): Promise<APIResponse<string[]>> {
    const url = this.usersUrl + "/professionalProfiles";
    const response = await this.api.get(url);
    return response;
  }
}