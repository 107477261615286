import { useTranslation } from "react-i18next";
import { AnimalsService } from "../../services";
import { useEffect, useState } from "react";
import { ButtonSelector } from "..";

interface IAnimalVaccinesSelectionProps {
  specieGuid: string;
  selectedVaccines?: string[];
  onVaccineSelected: (vaccineGuid: string) => void;
}

interface IVaccine {
  guid: string;
  name: string;
}

export const AnimalVaccinesSelection = (props: IAnimalVaccinesSelectionProps, {animalsService = new AnimalsService()}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [vaccines, setVaccines] = useState<Array<IVaccine>>([]);
  
  const onVaccineSelectedHandler = (guid: string, name: string) => {
    props.onVaccineSelected(guid);
  };

  const loadVaccines = async () => {
    setLoading(true);
    const response = await animalsService.getVaccines(props.specieGuid);
    const apiVaccines = response.data.map((x: IVaccine) => x).sort((a: IVaccine, b: IVaccine) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      return 0;
    });
    setVaccines(apiVaccines);
    setLoading(false);
  };

  useEffect(() => {
    loadVaccines();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  
  const vaccineItems = vaccines.map(vaccine =>
    <ButtonSelector
      key={`specieSelectionButton-${vaccine.guid}`}
      onClick={() => onVaccineSelectedHandler(vaccine.guid, vaccine.name)}
      selected={props.selectedVaccines?.includes(vaccine.guid) ?? false}>
        {vaccine.name}
    </ButtonSelector>
  );

  
    return (
      <>
        <h2>{t("vaccineSelectionTitle")}</h2>
        {loading && t("loading")}
        {!loading && <>
          <div>{vaccineItems}</div>
        </>}
      </>
    );
}